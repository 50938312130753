body {
  margin: 0;
  padding: 0;

  font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  line-height: 4vw;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header .headerWrapper {
  position: relative;
  width: 100vw;
  padding: 0 15vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .headerWrapper .headerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8vw;
}
header .headerWrapper .headerBtn .button {
  position: relative;
  cursor: pointer;
  color: #333333;
  width: 6vw;
  margin: 0 0.8vw;
  text-align: center;
  font-size: 1.3vw;
  line-height: 2vw;
  height: 2vw;
  border-width: 0;
  font-family: Impact;
  background-color: transparent;
  box-shadow: 0 0 0;
  font-weight: 600;
}
header .headerWrapper .headerBtn .button:hover {
  border-bottom: 1px solid #1E7C51;
  color: #1E7C51;
}
header .headerWrapper .headerBtn .active-btn {
  color: #1E7C51;
  border-width: 0;
  font-family: Impact;
  border-bottom: 1px solid #1E7C51;
  box-shadow: 0 0 0;
}
header .headerWrapper .headerBtn .active-btn:hover {
  color: #333;
}
header .btn-white {
  position: relative;
  background: #fedb67;
  padding: 0 18vw 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #3cf992;
  background-size: 50% 100%;
  background-position: left;
  background-repeat: no-repeat;
  position: relative;
}
.layout::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  background: #0d110e;
}
.layout .banner {
  height: 94vh !important;
}
.layout .banner .content .title {
  height: auto!important;
}
.layout .footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  height: 6vh !important;
  color: #262626;
}
.layout .footer .bar {
  height: 10vw;
  padding: 0 !important;
  justify-content: center;
}
.layout .footer .bar .one-center {
  width: 25vw !important;
}
.layout .footer .bar .one-center .infos {
  justify-content: flex-end !important;
}
.layout .footer .bar .one-center-end {
  margin-left: 12vw !important;
}
.layout .footer .bar .one-center-end .infos {
  justify-content: flex-start !important;
}
.layout .footer .bar .title {
  font-size: 1.4vw !important;
  height: 1.4vw !important;
  text-align: left;
  padding-top: 1.5vw !important;
}
.layout .footer .bar .infos {
  text-align: left;
  padding-bottom: 1vw !important;
  margin-bottom: 0 !important;
  font-size: 1vw !important;
}
.layout .footer .bar .infos a {
  text-align: left;
  font-size: 1vw!important;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.block {
  position: relative;
}
.banner {
  position: relative;
  margin: auto;
  width: 100vw;
  overflow: hidden;
  background-size: 100vw auto;
}
.banner .headerBox {
  height: 8vh;
  display: flex;
}
.banner .headerBox .headerLeft {
  width: 30%;
  height: 100%;
  background-color: #3cf992;
}
.banner .headerBox .headerRight {
  width: 70%;
  height: 100%;
  background-color: #0d110e;
}
.banner .content {
  height: 78vh;
  position: relative;
  z-index: 1;
  text-align: left;
  margin: 0 3%;
  background-color: #0d110e;
  border: 8px solid #3cf992;
  border-radius: 0 10vw 0 10vw;
  box-sizing: border-box;
  padding: 1.5vw 15.6vw;
}
.banner .content .logoBox {
  text-align: right;
  margin-bottom: 1vw;
}
.banner .content .logoBox .logo {
  height: 4.8vh;
}
.banner .content .title {
  font-size: 4.8vh;
  font-family: Impact;
  font-weight: 400;
  color: #3CF992;
  line-height: 6vh;
}
.banner .content .subtitle {
  margin-top: 1vw;
  font-size: 2.5vh;
  font-family: Impact;
  font-weight: 400;
  color: #fff;
}
.banner .content .desc {
  font-size: 2.5vh;
  font-family: Impact;
  font-weight: 400;
  margin-top: 1vw;
  color: #fff;
}
.banner .content .banner-btn {
  margin-top: 1vw;
  padding: 0.7vw 2vw;
  background: #3CF992;
  border-radius: 0 1.4vw 0 1.4vw;
  font-size: 2.5vh;
  font-family: Impact;
  font-weight: 400;
  color: #262626;
  opacity: 1;
  border: 0 !important;
}
.banner .content .banner-btn a {
  color: #262626;
  text-decoration: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner .content .banner-btn .banner-img {
  margin-right: 0.5vw;
  width: 3vh;
  height: 3vh;
}
.banner .content .bar {
  margin-top: 1vw;
  opacity: 1;
  color: #808080;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.banner .content .bar .one-center {
  font-family: Impact;
  font-weight: 600;
  position: relative;
}
.banner .content .bar .one-center .title {
  padding: 0;
  margin: 0;
  font-size: 3.1vh;
  color: #808080;
}
.banner .content .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 2.1vh;
}
.banner .content .bar .one-center .infos .contImg {
  width: 2.4vh;
  margin-right: 0.5vw;
  fill: #808080;
}
.banner .content .bar .one-center .infosBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.banner .content .bar .one-center-end .infos {
  text-align: right;
  justify-content: flex-end;
  margin-right: 1vw;
}
.banner .content .bar .one-center-end .infos a {
  color: #808080;
  text-align: right;
  font-family: Impact;
  font-weight: 600;
}
.banner .content .bar .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.banner .imgWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 1.6vw;
}
.banner .imgWrapper img {
  height: 25vh;
}
.banner-ims {
  position: absolute;
  left: -3px;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
}
.banner-andriod {
  width: 17px;
  height: 20px;
  margin: -5px 10px 0 5px;
}
.banner-newIcon {
  width: 47px;
  height: 47px;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 999;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-download {
  width: 275px;
  position: relative;
  height: 110px;
  color: #fff;
  display: flex;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download div {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: Impact;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.block1 {
  overflow: initial;
  width: 100vw;
  height: 46.6vw;
  padding-left: 18.8vw;
  padding-right: 18.8vw;
  position: relative;
  overflow: hidden;
  background-image: url(../../static/media/bg_feature.c94c5115.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 5.2vw;
  padding-top: 8.3vw;
}
.block1 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block1 .topTip h2 {
  font-size: 2.2vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 2.2vw;
  color: #FEDB67;
}
.block1 .topTip div {
  line-height: 1.8vw;
  width: 40.9vw;
  font-family: Impact;
  font-size: 1.1vw;
  font-weight: 600;
  color: #FEDB67;
  margin: 1.6vw auto 0;
}
.block1 .block-main-content {
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 0;
}
.block1 .block-main-content .fsTitle {
  padding: 0;
  margin: 0;
  height: 67px;
  font-size: 48px;
  font-family: Impact;
  font-weight: bold;
  line-height: 67px;
  color: #3b3291;
  opacity: 1;
}
.block1 .block-main-content .fsDesc {
  padding: 0;
  margin: 14px 0 88px 0;
  width: 560px;
  font-size: 29px;
  font-family: Impact;
  font-weight: 400;
  line-height: 35px;
  color: #5e57a7;
  opacity: 1;
}
.block1 .one-row-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}
.block1 .page-main-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block1 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.6vw;
}
.block1 .item-feature .cardInfo {
  width: 14.5vw;
  height: 20vw;
  background-color: #fff;
  border-radius: 1.6vw;
  color: #333333;
  text-align: center;
  padding: 0 1.4vw;
  padding-top: 2.3vw;
}
.block1 .item-feature .cardInfo .cardTitle {
  padding-top: 2.3vw;
  font-size: 1.2vw;
  font-family: Impact;
  font-weight: bold;
  line-height: 1.6vw;
  opacity: 1;
}
.block1 .item-feature .cardInfo .cardDetail {
  margin-top: 0.5vw;
  font-size: 0.8vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 1.15vw;
  opacity: 1;
}
.block1 .item0 .cardIcon {
  height: 5vw;
}
.block1 .item1 .cardIcon {
  height: 5vw;
}
.block1 .item2 .cardIcon {
  height: 5vw;
}
.block1 .item3 .cardIcon {
  height: 5vw;
}
.block1 .one-row-block :nth-child(odd) div img {
  width: 150px;
  height: 150px;
  left: calc(50% - 75px);
}
.block1 .one-row-block :nth-child(even) div img {
  width: 170px;
  height: 130px;
  left: calc(50% - 85px);
}
.block1 .card {
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  color: #868e96;
  display: inline-block;
  max-width: 360px;
  height: 272px;
  padding: 32px;
  box-shadow: 0 2px 2px rgba(84, 48, 132, 0.06);
  margin: 0 auto;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  text-align: center;
  border-radius: 4px;
}
.block1 .card .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.block1 .card h3 {
  font-size: 30px;
}
.block1 .card img {
  height: 50px;
  margin: 20px 0;
}
.block1 .card:hover {
  text-decoration: none;
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(84, 48, 132, 0.06);
}
.block3 {
  margin-top: 0;
  overflow: initial;
  height: 53vw;
  width: 100vw;
  padding: 0 22vw;
  padding-bottom: 5vw;
  position: relative;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
}
.block3 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: left;
  padding-left: 1vw;
}
.block3 .topTip h2 {
  font-family: Impact;
  font-size: 3vw;
  font-weight: 600;
  color: #1E7C51;
  text-align: center;
  margin-bottom: 0;
}
.block3 .topTip div {
  width: 33vw;
  margin: 0 auto;
  font-size: 1.1vw;
  font-family: Impact;
  line-height: 1.2vw;
  font-weight: 600;
  color: #1E7C51;
  text-align: center;
}
.block3 .page {
  padding: 0 2.3vw;
}
.block3 .one-card {
  background: #ffffff;
  opacity: 1;
  border-radius: 1.8vw;
  display: flex;
  align-items: center;
  margin-top: 1.6vw;
  padding: 1.6vw 1.4vw;
}
.block3 .one-card img {
  width: 8.3vw;
  height: 8.3vw;
}
.block3 .one-card .common-card {
  margin-left: 2vw;
  padding-top: 1vw;
}
.block3 .one-card .common-card .title {
  font-size: 1.1vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 1.1vw;
  margin-bottom: 0;
}
.block3 .one-card .common-card .detail {
  font-size: 1vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 1.1vw;
  color: #333333;
  margin-top: 1vw;
}
.block3 .one-card .common-card .detail p {
  margin-bottom: 0.5vw;
}
.block3 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block3 .item-feature .cardInfo {
  width: 20vw;
}
.block3 .item-feature .cardInfo .cardTitle {
  font-size: 2vw;
  font-family: Impact;
  font-weight: bold;
  line-height: 3vw;
  opacity: 1;
  color: #2972FB;
}
.block3 .item-feature .cardInfo .cardDetail {
  margin-top: 1vh;
  font-size: 1.5vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 2vw;
  opacity: 1;
}
.block3 .item-feature .card0 {
  text-align: left;
}
.block3 .item-feature .card1 {
  text-align: right;
}
.block3 .item-feature .card1 .cardTitle {
  text-align: right;
}
.block3 .item0 .cardIcon {
  width: 3.3vw;
  margin-right: 7vw;
}
.block3 .item1 {
  margin-top: 7vw;
}
.block3 .item1 .cardIcon {
  width: 20vw;
}
.block3 .item2 {
  margin-top: 7vw;
}
.block3 .item2 .cardIcon {
  margin-right: 7vw;
  width: 15vw;
}
.block3 .item3 {
  margin-top: 14vw;
}
.block3 .item3 .cardIcon {
  width: 18vw;
}
.block4 {
  min-height: 715px;
  padding: 130px 0;
  background: #F8FAFE;
}
.block4 .slick-slide {
  height: 455px;
}
.block4 .slick-slide .user {
  width: 98px;
  height: 98px;
  border-radius: 100%;
  margin: auto;
}
.block4 .slick-slide .comment {
  line-height: 48px;
  font-size: 20px;
  width: 100%;
  max-width: 680px;
  margin: 41px auto 47px;
  position: relative;
  color: #545454;
  font-weight: 300;
}
.block4 .slick-slide .comment > img {
  position: absolute;
  left: -40px;
  top: -15px;
}
.block4 .slick-slide > h4 {
  font-size: 30px;
  line-height: 38px;
  font-weight: normal;
}
.block4 .slick-slide > p {
  margin-top: 16px;
  font-size: 14px;
  color: #888888;
  font-weight: 300;
}
.block4 .slick-dots li {
  margin: 0 8px;
}
.block4 .slick-dots li button {
  transition: opacity 0.3s;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
}
.block4 .slick-dots li button:hover::before,
.block4 .slick-dots li button:focus::before {
  opacity: 1;
  width: 20px;
  height: 20px;
}
.block4 .slick-dots li button::before {
  transition: color 0.3s;
}
.block4 .slick-dots li.slick-active button {
  background: #064852;
}
.block4 .slick-dots li.slick-active button::before {
  opacity: 1;
  background: #064852;
}
.block5 {
  min-height: 590px;
}
.block5 h2 {
  margin: 120px auto 25px;
}
.block5 > span {
  margin-bottom: 72px;
}
.block5 .ant-row > div {
  margin-bottom: 20px;
}
.andriod {
  width: 17px;
  height: 18px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer {
  overflow: hidden;
  width: 100vw;
}
.footer .bottom-bar {
  overflow: hidden;
  height: 6vh;
  line-height: 6vh;
  text-align: center;
  background: #1A1A1A;
  color: #FFFFFF;
  font-size: 1.4vh;
  font-family: PingFang SC;
  font-weight: 400;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.layout {
  position: fixed;
  height: 100vh;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #3cf992;
  background-size: 50% 100%;
  background-position: left;
  background-repeat: no-repeat;
  position: relative;
}
.layout::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  background: #0d110e;
}
.layout .banner {
  height: 94vh !important;
}
.layout .banner .content .title {
  height: auto!important;
}
.layout .footer {
  position: fixed!important;
  z-index: 2!important;
  bottom: 0!important;
  height: 6vh !important;
  color: #262626;
}
.layout .footer .bar {
  height: 10vw;
  padding: 0 !important;
  justify-content: center;
}
.layout .footer .bar .one-center {
  width: 25vw !important;
}
.layout .footer .bar .one-center .infos {
  justify-content: flex-end !important;
}
.layout .footer .bar .one-center-end {
  margin-left: 12vw !important;
}
.layout .footer .bar .one-center-end .infos {
  justify-content: flex-start !important;
}
.layout .footer .bar .title {
  font-size: 1.4vw !important;
  height: 1.4vw !important;
  text-align: left;
  padding-top: 1.5vw !important;
}
.layout .footer .bar .infos {
  text-align: left;
  padding-bottom: 1vw !important;
  margin-bottom: 0 !important;
  font-size: 1vw !important;
}
.layout .footer .bar .infos a {
  text-align: left;
  font-size: 1vw!important;
}
#reat-content {
  font-family: Impact;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 3.7vw;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.bodyMob {
  width: 100%;
  overflow-x: hidden;
  background: #0D110E;
}
.bodyMob .btn-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  width: 100%;
  overflow-x: hidden;
}
.banner-mobile .content {
  position: relative;
  z-index: 1;
  height: 100%;
  color: #fff;
}
.banner-mobile .content .logoBox {
  padding: 1vh 0;
  text-align: center;
}
.banner-mobile .content .logoBox img {
  height: 5vh;
}
.banner-mobile .content .title {
  position: relative;
  font-family: Impact;
  padding-top: 1.2vw;
  width: 81.5vw;
  font-size: 6vw;
  line-height: 7vw;
  font-weight: 400;
  margin-left: 7vw;
  color: #3CF992;
}
.banner-mobile .content .subtitle {
  margin-left: 7vw;
  width: 80vw;
  position: relative;
  padding-top: 4.6vw;
  font-size: 4.3vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 1vw;
}
.banner-mobile .content .desc {
  width: 80vw;
  margin-left: 7vw;
  font-size: 4.3vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.5vw;
  margin-bottom: 5.6vw;
}
.banner-mobile .content .word-download {
  margin-top: 4vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-mobile .content .banner-btn {
  width: 70vw;
  position: relative;
  text-align: center;
  margin: 0 auto;
  height: 10vw;
  border: 0;
  background: #3CF992;
  opacity: 1;
  border-radius: 0 4.2vw 0 4.2vw;
  font-size: 4vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.6vw;
  margin-bottom: 3.5vw;
  color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-mobile .content .banner-btn a {
  text-decoration: initial;
  color: #262626;
}
.banner-mobile .content .banner-btn .banner-img {
  width: 3.7vw;
  height: 3.7vw;
  margin-right: 1vw;
}
.banner-mobile .imgWrapper {
  position: relative;
  z-index: 0;
  margin-top: 4vh;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.banner-mobile .imgWrapper img {
  width: 45vw;
  height: 40vw;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
.block1-mobile {
  overflow: initial;
  position: relative;
  overflow: hidden;
}
.block1-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding-left: 7.1vw;
  padding-right: 7.1vw;
}
.block1-mobile .topTip h2 {
  height: 2.3vw;
  font-size: 4vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4vw;
  color: #1E7C51;
}
.block1-mobile .topTip img {
  width: 40.5vw;
  height: 4.3vw;
}
.block1-mobile .topTip div {
  padding-top: 1.8vw;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 4vw;
  color: #fff;
}
.block1-mobile .page-main-content {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 0 4.5vw;
  padding-top: 4.6vw;
  padding-bottom: 9.3vw;
  margin-top: -1px;
}
.block1-mobile .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.block1-mobile .item-feature .cardInfo {
  position: relative;
  width: 50vw;
  height: 37vw;
  background: #ffffff;
  border-radius: 2.6vw;
  color: #333333;
}
.block1-mobile .item-feature .cardInfo .cardTitle {
  width: 24vw;
  height: 4.6vw;
  font-size: 3.1vw;
  font-family: Impact;
  font-weight: 400;
  line-height: 4.6vw;
  opacity: 1;
  color: #fff;
}
.block1-mobile .item-feature .cardInfo .cardDetail {
  width: 49.2vw;
  font-size: 1.2vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 2.1vw;
  opacity: 1;
  margin-bottom: 5vw;
}
.block1-mobile .item-feature .card0 {
  text-align: left;
  padding: 3.3vw 0 0 4.6vw;
}
.block1-mobile .item-feature .card0 .cardTitle {
  font-size: 3vw;
}
.block1-mobile .item-feature .card0 .cardDetail {
  width: 36.8vw;
  line-height: 4.3vw;
  font-size: 2vw;
}
.block1-mobile .item-feature .card1 {
  text-align: right;
}
.block1-mobile .item-feature .card1 .cardTitle {
  margin-left: -6vw;
  font-size: 3vw;
  line-height: 4.6vw;
  width: 49.2vw;
  margin-top: 5vw;
}
.block1-mobile .item-feature .card1 .cardDetail {
  width: 39vw;
  font-size: 2vw;
  line-height: 4.3vw;
  margin-left: 4vw;
}
.block1-mobile .item0 {
  margin-top: 4.5vw;
}
.block1-mobile .item0 .cardIcon {
  width: 28.7vw;
  margin-right: 7vw;
  position: absolute;
  left: 6vw;
  bottom: -12vw;
  opacity: 0.3;
}
.block1-mobile .item0 .cardInfo .cardDetail {
  margin-bottom: 3vw;
}
.block1-mobile .item1 {
  margin-top: -7vw;
  margin-left: 43vw;
}
.block1-mobile .item1 .cardIcon {
  width: 29.7vw;
  margin: -24vw 3vw 1vw 0;
  position: absolute;
  left: 12vw;
  top: 10vw;
  opacity: 0.3;
}
.block1-mobile .item2 {
  margin-top: -7vw;
}
.block1-mobile .item2 .cardIcon {
  margin-right: 7vw;
  width: 23.7vw;
  position: absolute;
  left: 6vw;
  bottom: -16vw;
  opacity: 0.3;
}
.block1-mobile .item2 .cardInfo .cardDetail {
  line-height: 4.6vw;
  margin-bottom: 6vw;
  margin-top: 1vw;
}
.block1-mobile .item3 {
  margin-top: -7vw;
  margin-left: 43vw;
}
.block1-mobile .item3 .cardIcon {
  width: 30.3vw;
  margin: -19vw 3vw 3vw 0;
  position: absolute;
  left: 11vw;
  top: 9vw;
  opacity: 0.3;
}
.block1-mobile .item3 .cardInfo .cardTitle {
  margin-top: 9vw;
}
.block3-mobile {
  overflow: initial;
  padding: 0 3.7vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding-bottom: 4.2vw;
  margin-top: -1px;
}
.block3-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block3-mobile .topTip h2 {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 4.5vw;
  color: #fff;
}
.block3-mobile .topTip div {
  margin-top: 1.8vw;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 4vw;
  color: #1E7C51;
  opacity: 1;
  margin-bottom: 4.6vw;
}
.block3-mobile .one-card {
  background: #ffffff;
  opacity: 1;
  border-radius: 4.2vw;
  display: flex;
  align-items: center;
  margin-top: 2.8vw;
  padding: 2.1vw 3.5vw;
}
.block3-mobile .one-card img {
  width: 20vw;
}
.block3-mobile .one-card .cardImg {
  width: 20vw;
}
.block3-mobile .one-card .common-card {
  margin-left: 3.7vw;
  padding-top: 2vw;
}
.block3-mobile .one-card .common-card .title {
  font-size: 3.3vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 4.6vw;
  margin-bottom: 1.5vw;
}
.block3-mobile .one-card .common-card .detail {
  margin-top: 0.8vw;
  font-size: 3vw;
  font-family: Impact;
  font-weight: 600;
  line-height: 4.3vw;
  color: #333333;
  padding-right: 1vw;
}
.block3-mobile .one-card .common-card .detail p {
  margin-bottom: 0;
}
.footer-mobile {
  margin-top: 7vw;
  overflow: hidden;
  padding: 0 4.5vw;
}
.footer-mobile .one-center {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 1.4vw;
  padding: 5.4vw 0;
  border-radius: 2.1vw;
  font-family: Impact;
  font-weight: 400;
  position: relative;
  text-align: center;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  line-height: 5.3vw;
  color: #fff;
  opacity: 1;
}
.footer-mobile .one-center .infos {
  height: 4.4vw;
  opacity: 1;
  border-radius: 1.8vw;
  width: 48vw;
  margin: 0 auto;
  font-size: 3.4vw;
  font-weight: 400;
  line-height: 6.4vw;
  color: #fff;
  margin-top: 2.6vw;
}
.footer-mobile .one-center .infos a {
  color: #fff;
  text-align: left;
  font-family: Impact;
}
.footer-mobile .one-center-end {
  margin-top: 5.1vw;
  background: #3CF992;
  opacity: 1;
  text-align: left;
  border-radius: 2.8vw 2.8vw 0 0;
  padding: 4.5vw 9vw 5vh;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: Impact;
  line-height: 4vw;
  color: #262626;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2.6vw;
}
.footer-mobile .one-center-end .infos div {
  height: 4vw;
  line-height: 4vw;
  font-size: 3.4vw;
  font-family: Impact;
  font-weight: 500;
  color: #262626;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 3.7vw;
  height: 3vw;
  margin-right: 1.8vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #064852;
  color: #262626;
}
.ant-menu-item::after {
  border-right: 1vw solid #064852 !important;
}

.contentWrapper {
  background: #fff;
  padding: 20px;
  width: 800px;
  margin: auto;
  border: 1px solid #e5e5e5;
}
.contentWrapper h1 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    background: #fff;
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #262626;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 15px;
    line-height: 30px;
    color: #262626;
  }
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  width: 100%;
  justify-content: center!important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  justify-content: center!important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0!important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0!important;
  padding: 1vh 6vw!important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto!important;
  line-height: initial!important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  z-index: 100!important;
  bottom: 35vh!important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center!important;
  margin: 0 auto!important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh!important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  margin: 0 15vw;
  background-color: #ffffff;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #2d312e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorMsg {
  color: red;
  text-align: center;
  background-color: #2d312e;
  padding: 20px;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #3cf992;
  color: #000;
  cursor: pointer;
  font-size: 34px;
  padding: 10px 20px;
}
.deleteWrapper-mob {
  padding: 35px 0;
  padding-top: 108px;
  position: relative;
  min-height: 100vh;
  background-color: #0D110E;
}
.deleteWrapper-mob .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90vw;
  z-index: 100;
  z-index: 0;
  background: #0D110E;
}
.deleteWrapper-mob .tabNav .title {
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0;
  padding-top: 8.7vw;
  font-size: 3.9vw;
}
.deleteWrapper-mob .content {
  position: fixed;
  top: 18vw;
  left: 4.5vw;
  z-index: 1;
  right: 4.5vw;
  border-radius: 0 0 2.8vw 2.8vw;
}
.deleteWrapper-mob .tip {
  position: relative;
  padding: 3vw 0;
  margin: 0 28vw;
  text-align: center;
  font-size: 4vw;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  background: #2D312E;
  letter-spacing: 0px;
  border-radius: 2.8vw 2.8vw 0px 0px;
  border: 1px solid #3CF992;
  border-bottom: 1px solid #2d312e;
  z-index: 999;
  margin-bottom: -1px;
}
.deleteWrapper-mob .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 25px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 38px;
  color: #FB4402;
  text-align: left;
  padding: 0 35px;
}
.deleteWrapper-mob .fbtInfo {
  position: relative;
  opacity: 1;
  border-radius: 21px;
  border-radius: 3.2vw;
  border: 1px solid #3CF992;
  background: #0D110E;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  padding: 9.3vw 4.6vw;
}
.deleteWrapper-mob .fbtCol {
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 42px;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #FB4402;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.deleteWrapper-mob .fbtCol .need {
  color: #FB4402;
}
.deleteWrapper-mob .fbtPhone {
  background: #2D312E;
  padding: 2.2vw 0;
  opacity: 1;
  width: 100%;
  margin-bottom: 4.6vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin-left: 4.6vw;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
  margin-left: 2.8vw;
  border: 0;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #808080;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput::placeholder {
  color: #808080;
  height: 5vw;
  line-height: 5vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpInput:focus {
  outline: none;
}
.deleteWrapper-mob .fbtPhone .fpInfo .fpIdCard {
  padding-left: 4.6vw;
  margin-left: 0;
}
.deleteWrapper-mob .fbtPhone .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper-mob .fbtPhone .showTips span {
  font-size: 2.2vw;
  color: #b92d00;
}
.deleteWrapper-mob .fbtPhone::-webkit-input-placeholder {
  color: #808080;
}
.deleteWrapper-mob .fbtPhone::placeholder {
  color: #808080;
}
.deleteWrapper-mob .itemSelect {
  padding-left: 2.2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #2D312E;
  opacity: 1;
  font-size: 3.3vw;
  margin-bottom: 4.6vw;
  border-radius: 0 1vh 0 0;
}
.deleteWrapper-mob .itemSelect .iSelect {
  border: 0;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  height: 5vw;
  line-height: 5vw;
  color: #fff;
  width: 100%;
  background: initial;
  margin: 1vh 0;
}
.deleteWrapper-mob .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper-mob .itemSelect .iSelect::-webkit-input-placeholder {
  color: #808080;
}
.deleteWrapper-mob .itemSelect .iSelect::placeholder {
  color: #808080;
}
.deleteWrapper-mob .itemSelect .iSelect:focus {
  outline: none;
}
.deleteWrapper-mob .itemSelect .itemImg {
  height: 8vw;
  cursor: pointer;
}
.deleteWrapper-mob .fbtContent {
  width: 100%;
  padding: 2.2vw 4.6vw;
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: #2D312E;
  box-sizing: border-box;
  border: 0;
}
.deleteWrapper-mob .fbtContent .fpLabel {
  font-size: 3.3vw;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper-mob .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #fff;
  background-color: #2D312E;
  width: 100%;
  height: 18vw;
  font-size: 3.3vw;
}
.deleteWrapper-mob .fbtContent .fpText:focus {
  outline: none;
}
.deleteWrapper-mob .fbtContent .fpText::-webkit-input-placeholder {
  color: #808080;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtContent .fpText::placeholder {
  color: #808080;
  font-size: 3.3vw;
  height: 5vw;
  line-height: 5vw;
}
.deleteWrapper-mob .fbtContent .fpInput:focus {
  outline: none;
}
.deleteWrapper-mob .itemSelect::-webkit-input-placeholder {
  color: #808080;
}
.deleteWrapper-mob .itemSelect::placeholder {
  color: #808080;
}
.deleteWrapper-mob .fbtContentOn {
  border: 1px solid #3CF992;
  opacity: 1;
}
.deleteWrapper-mob .idCard {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper-mob .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper-mob .agreeWrapper {
  margin-top: 5.9vw;
  margin-bottom: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper-mob .agreeWrapper .laCheck {
  position: relative;
  width: 3.6vw;
  height: 3.6vw;
  border-radius: 50%;
  background-color: #fff;
}
.deleteWrapper-mob .agreeWrapper .hasCheck {
  background-color: #3CF992;
}
.deleteWrapper-mob .agreeWrapper .hasCheck .laImg {
  position: absolute;
  top: 0.8vw;
  width: 3vw;
  height: 2vw;
}
.deleteWrapper-mob .agreeWrapper .laText {
  display: flex;
  margin-left: 2.6vw;
  color: #fff;
  letter-spacing: 0px;
  font-size: 2.5vw;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vw;
  cursor: pointer;
}
.deleteWrapper-mob .agreeWrapper .laText .normolColor {
  color: #fff;
}
.deleteWrapper-mob .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #fff;
  font-weight: bold;
  padding: 0 0.1vw;
}
.deleteWrapper-mob .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 11vw;
  height: 9.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3CF992;
  opacity: 0.4;
  font-size: 3.9vw;
  font-family: Roboto;
  font-weight: bold;
  color: #0D110E;
  letter-spacing: 0px;
  margin: 0 8.3vw;
  border-radius: 5.6vw;
}
.deleteWrapper-mob .fbtActionOn {
  opacity: 1;
  cursor: pointer;
}
.deleteWrapper-mob .fbtSuccess {
  position: relative;
  display: flex;
  height: 100vh;
  margin: 0 auto;
  padding: 0 3.5vw;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
}
.deleteWrapper-mob .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor-mob {
  padding-top: 0;
  background-color: #f7f7f7;
  color: #fb4402;
}
.deleteWrapperColor-mob .tabNav {
  height: 100vh;
}
.copySuccessTip-mob {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip-mob .iconsmile {
  width: 54px;
  height: 54px;
  fill: #fb4402;
  margin-right: 9px;
}
.copySuccessTip-mob span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #fb4402;
  opacity: 1;
}
.edit-mob .content {
  overflow: hidden;
  height: 70vh !important;
  border-radius: 21px;
}
.edit-mob .content .tip {
  display: none;
}
.edit-mob .content .fbtInfo {
  display: block;
  height: 100%;
  padding-bottom: 80vw;
  overflow-y: scroll;
}
.selectModal-mob .modalContent {
  border-radius: 2.8vw 2.8vw 0px 0px;
  margin: 0 4.6vw;
}
.selectModal-mob .modalContent .listHead {
  font-size: 3.9vw;
}
.selectModal-mob .modalContent .list .listItem {
  font-size: 3.9vw;
  margin: 0 4.6vw;
  margin-bottom: 3vw;
}
.selectModal-mob .modalContent .list .listItemOn {
  border-bottom: 0;
}

.am-modal-body {
  color: #666;
  font-size: 33px !important;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  width: 100%;
  justify-content: center!important;
}
.am-list {
  width: 100%;
}
.am-modal-popup-slide-up {
  top: initial;
  bottom: initial;
  width: 100%;
  display: flex!important;
  height: 100%!important;
  align-items: center!important;
  justify-content: center!important;
}
.am-list-content {
  margin: 0 35px;
}
.ant-notification {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  top: 0!important;
}
.ant-notification-notice {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.ant-notification-notice-content {
  padding: 3vh 2vw;
  line-height: initial;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3vw;
}
.ant-notification-notice-message {
  color: #fff;
  margin: 0!important;
  padding: 1vh 6vw!important;
  font-size: 2.5vh !important;
}
.ant-notification-notice-icon {
  position: relative !important;
}
.ant-notification-notice-icon-error {
  margin: 0;
  padding: 0;
  font-size: 4.5vh;
  line-height: initial;
  color: red;
}
.ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: auto!important;
  line-height: initial!important;
  width: auto !important;
}
.ant-notification-topRight {
  width: 300px;
}
.ant-notification-notice-close-x {
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  z-index: 100!important;
  bottom: 35vh!important;
  width: 4vh;
  height: 4vh;
  line-height: 4vh;
  text-align: center!important;
  margin: 0 auto!important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ant-notification-notice-close-x:after {
  font-size: 3vh!important;
  line-height: 4vh;
}
.errorModal {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.errorContent {
  position: relative;
  margin: 0 15vw;
  background-color: #ffffff;
  overflow: hidden;
}
.loadingContent {
  background-color: rgba(0, 0, 0, 0.5);
}
.errorHeader {
  background: #2d312e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorMsg {
  color: red;
  text-align: center;
  background-color: #2d312e;
  padding: 20px;
}
.loadingMsg {
  color: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  30% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  60% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn {
  background-color: #3cf992;
  color: #000;
  cursor: pointer;
  font-size: 34px;
  padding: 10px 20px;
}
.deleteWrapper {
  padding: 2.1vh;
  padding-top: 8vh;
  position: relative;
  min-height: 100vh;
  background-color: #0D110E;
}
.deleteWrapper .tabNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70vh;
  z-index: 1;
  background: #0D110E;
}
.deleteWrapper .tabNav .title {
  padding-top: 3.5vh;
  font-size: 2.6vh;
  font-family: Roboto;
  font-weight: bold;
  text-align: center;
  color: #fff;
  letter-spacing: 0px;
}
.deleteWrapper .content {
  position: fixed;
  z-index: 1;
  top: 10vh;
  height: 90vh;
  left: 5vw;
  right: 5vw;
}
.deleteWrapper .tip {
  position: relative;
  padding: 3vh 0;
  margin: 0 30vw;
  text-align: center;
  font-size: 3.6vw;
  font-family: Roboto;
  font-weight: bold;
  line-height: 3.5vh;
  color: #fff;
  letter-spacing: 0px;
  background: #2D312E;
  opacity: 1;
  border-radius: 28px 28px 0px 0px;
  border: 1px solid #3CF992;
  border-bottom: 1px solid #2d312e;
  z-index: 999;
  margin-bottom: -1px;
}
.deleteWrapper .desc {
  position: relative;
  display: flex;
  padding-right: 0;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 2.3vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 2.5vh;
  color: #FB4402;
  text-align: left;
}
.deleteWrapper .fbtInfo {
  position: relative;
  opacity: 1;
  padding: 6.9vh 3.5vh;
  border-radius: 2.4vh;
  border: 1px solid #3CF992;
  background: #0D110E;
  overflow: hidden;
}
.deleteWrapper .fbtCol {
  position: relative;
  padding-top: 1.4vh;
  padding-bottom: 1.2vh;
  font-size: 2.2vh;
  line-height: 2.5vh;
  font-family: Roboto;
  font-weight: 400;
  color: #FB4402;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  padding-left: 4.5vh;
}
.deleteWrapper .fbtCol .need {
  color: #FB4402;
}
.deleteWrapper .fbtPhone {
  background: #2D312E;
  padding: 1vh 0;
  opacity: 1;
  width: 100%;
  margin-bottom: 5vh;
}
.deleteWrapper .fbtPhone .fpInfo {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper .fbtPhone .fpInfo .fpLabel {
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0px;
  margin-left: 2.2vw;
  font-size: 2vh;
  height: 2.2vh;
  line-height: 2.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput {
  position: relative;
  width: 100%;
  font-family: Roboto;
  font-weight: bold;
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  letter-spacing: 0px;
  height: 3.2vh;
  line-height: 3.2vh;
  font-size: 2vh;
  margin-left: 1.1vw;
  border: 0;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::-webkit-input-placeholder {
  color: #808080;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput::placeholder {
  color: #808080;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtPhone .fpInfo .fpInput:focus {
  outline: none;
}
.deleteWrapper .fbtPhone .fpInfo .fpIdCard {
  padding-left: 2.2vw;
  margin-left: 0;
}
.deleteWrapper .fbtPhone .showTips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2.2vw;
}
.deleteWrapper .fbtPhone .showTips span {
  font-size: 2vh;
  color: #b92d00;
}
.deleteWrapper .fbtPhone .showTips img {
  margin-left: 2.2vw;
  width: 2vh;
  height: 2vh;
}
.deleteWrapper .fbtPhone::-webkit-input-placeholder {
  color: #808080;
}
.deleteWrapper .fbtPhone::placeholder {
  color: #808080;
}
.deleteWrapper .itemSelect {
  padding-left: 2.2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #2D312E;
  opacity: 1;
  font-size: 2vh;
  margin-bottom: 5vh;
  border-radius: 0 1vh 0 0;
}
.deleteWrapper .itemSelect .iSelect {
  border: 0;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  height: 3.2vh;
  line-height: 3.2vh;
  color: #fff;
  width: 100%;
  background: initial;
  margin: 1vh 0;
}
.deleteWrapper .itemSelect .itemUppercase {
  text-transform: uppercase;
}
.deleteWrapper .itemSelect .iSelect::-webkit-input-placeholder {
  color: #808080;
}
.deleteWrapper .itemSelect .iSelect::placeholder {
  color: #808080;
}
.deleteWrapper .itemSelect .iSelect:focus {
  outline: none;
}
.deleteWrapper .itemSelect .itemImg {
  height: 5vh;
  cursor: pointer;
}
.deleteWrapper .fbtContent {
  width: 100%;
  padding: 1vh 2.2vw;
  font-size: 2vh;
  font-family: Roboto;
  font-weight: bold;
  color: #000;
  letter-spacing: 0px;
  opacity: 1;
  background: #2D312E;
  box-sizing: border-box;
  border: 0;
}
.deleteWrapper .fbtContent .fpLabel {
  font-size: 21px;
  font-family: Roboto;
  font-weight: bold;
  color: #333333;
  letter-spacing: 0px;
  text-align: left;
}
.deleteWrapper .fbtContent .fpText {
  border: 0 !important;
  font-weight: bold;
  color: #fff;
  background-color: #2D312E;
  padding-top: 0.1vh;
  width: 100%;
  height: 10vh;
  font-size: 2vh;
}
.deleteWrapper .fbtContent .fpText::-webkit-input-placeholder {
  color: #808080;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtContent .fpText::placeholder {
  color: #808080;
  font-size: 2vh;
  height: 3.2vh;
  line-height: 3.2vh;
}
.deleteWrapper .fbtContent .fpText:focus {
  outline: none;
}
.deleteWrapper .itemSelect::-webkit-input-placeholder {
  color: #808080;
}
.deleteWrapper .itemSelect::placeholder {
  color: #808080;
}
.deleteWrapper .fbtContentOn {
  border: 1px solid #3CF992;
  opacity: 1;
}
.deleteWrapper .idCard {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo {
  opacity: 0.7;
}
.deleteWrapper .idCard .fpInfo .fpInput {
  opacity: 0.7;
}
.deleteWrapper .agreeWrapper {
  margin: 0 auto;
  margin-top: 2.2vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.deleteWrapper .agreeWrapper .laCheck {
  position: relative;
  width: 2.4vh;
  height: 2.4vh;
  margin-left: 8px;
  margin-top: 2px;
  margin-right: 1.1vh;
  border-radius: 50%;
  background-color: #fff;
}
.deleteWrapper .agreeWrapper .hasCheck {
  margin-left: 8px;
  margin-top: 2px;
  background-color: #3CF992;
}
.deleteWrapper .agreeWrapper .hasCheck .laImg {
  position: absolute;
  left: 0.2vh;
  top: 0.4vh;
  width: 2vh;
  height: 1.5vh;
}
.deleteWrapper .agreeWrapper .laText {
  display: flex;
  margin-left: 1.1vh;
  color: #fff;
  letter-spacing: 0px;
  font-size: 2.1vh;
  font-family: Roboto;
  font-weight: 400;
  line-height: 3.1vh;
  cursor: pointer;
}
.deleteWrapper .agreeWrapper .laText .normolColor {
  color: #fff;
}
.deleteWrapper .agreeWrapper .laText .doc {
  text-decoration: underline;
  color: #fff;
  font-weight: bold;
  padding: 0 1.1vw;
}
.deleteWrapper .fbtAction {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-weight: bold;
  background: #3CF992;
  opacity: 0.4;
  bottom: 2vh;
  height: 6.8vh;
  opacity: 0.7;
  font-size: 3.3vh;
  color: #0D110E;
  letter-spacing: 0px;
  margin: 0 10.4vh;
  border-radius: 2.1vh;
}
.deleteWrapper .fbtActionOn {
  opacity: 1;
  cursor: pointer;
}
.deleteWrapper .fbtSuccess {
  position: relative;
  display: flex;
  height: 90vh;
  z-index: 100;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 4.8vh;
  font-family: Roboto;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
}
.deleteWrapper .fbtSuccess span {
  font-size: 4.8vw;
  text-align: center;
  font-family: Roboto;
}
.deleteWrapperColor {
  background-color: #0D110E;
  color: #fb4402;
  padding-top: 0;
}
.deleteWrapperColor .tabNav {
  height: 100vh;
}
.copySuccessTip {
  height: 54px;
  align-items: center;
  display: flex;
  padding: 5px 0;
}
.copySuccessTip .iconsmile {
  width: 54px;
  height: 54px;
  fill: #fb4402;
  margin-right: 9px;
}
.copySuccessTip span {
  font-size: 33px;
  font-family: Roboto;
  font-weight: bold;
  color: #fb4402;
  opacity: 1;
}
.edit {
  height: auto;
  padding-bottom: 40vh;
}
.list {
  max-height: 60vh;
  overflow: scroll;
  text-align: center;
}
.list .am-list-content {
  overflow: initial !important;
  text-overflow: initial !important;
  white-space: initial!important;
  font-size: 2.1vh !important;
  padding-top: 1.2vh !important;
  padding-bottom: 1.2vh !important;
}
.list .am-radio-input {
  height: 5vw;
}
.list .am-list-item {
  min-height: 4vh!important;
}
.list .am-radio {
  height: 4vh!important;
}
.list .am-radio-inner {
  right: 0.5vh !important;
  top: 2vw!important;
  width: 2.2vh;
  height: 2.2vh;
}
.list .am-radio-inner:after {
  width: 1.2vh;
  height: 2.2vh;
}
.list .listItem {
  text-align: center;
}
.list .listItemTip {
  font-size: 2.1vh;
  color: #999999;
  text-align: center;
}
.list .listItemTip .listItemTSign {
  color: #B92D00;
}
.selectModal {
  position: fixed;
  left: 0 ;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: flex-end;
}
.modalContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #3CF992;
  background: #1A1A1A;
  margin: 0 3.5vh;
  bottom: 30vh;
  padding-bottom: 2vh;
}
.modalContent .listHead {
  font-weight: bold;
  color: #3CF992;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 1;
  height: 8vh;
  line-height: 8vh;
  font-size: 2.2vh;
  font-family: Roboto;
}
.modalContent .listHead .icon {
  width: 35px;
  height: 35px;
}
.modalContent .list {
  position: relative;
  background-color: #1A1A1A;
  overflow: auto;
  text-align: center;
  max-height: 60vh;
}
.modalContent .list .listItem {
  position: relative;
  margin: 0 69px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  padding: 1.5vh 0;
  border: 1px solid #FFFFFF;
  margin-bottom: 2vh;
}
.modalContent .list .listItemOn {
  color: #0D110E;
  background-color: #3CF992;
  border-bottom: 0;
  font-weight: bold;
  border: 0;
}
.modalContent .list .listItemOn .listItem {
  position: relative;
  color: #FB4402;
}
.modalContent .list::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 40px;
}
.modalContent .list::-webkit-scrollbar-button {
  color: #333333;
}
.modalContent .list::-webkit-scrollbar-thumb {
  background-position: center;
  border-radius: 10px;
  height: 40px;
  background-color: blue;
}

